function Home() {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="text-center">
        <h1 className="text-5xl font-semibold">Welcome to my Portfolio</h1>
        <p className="text-2xl font-semibold">More content coming soon...✌️</p>
      </div>
    </div>
  );
}

export default Home;
